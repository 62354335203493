<template>
  <v-form ref="form" v-model="valid" :lazy-validation="lazy">
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent title="사용자 생성" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!-- max-width="375" -->
          <v-card class="mx-auto">
            <!-- <v-img
              :src="user.user_id ? `https://avatars.dicebear.com/api/jdenticon/${user.user_id}.svg` : `https://avatars.dicebear.com/api/jdenticon/unknown.svg`"
              height="300px"
              dark
            /> -->

            <v-list class="mt-4">
              <v-list-item>
                <v-text-field
                  v-model="user.user_extra.real_name"
                  label="이름"
                  placeholder="이름을 입력해주세요"
                  hint="이름을 입력해주세요"
                  :rules="[rules.required]"
                />
              </v-list-item>
              <v-list-item>
                <v-text-field
                  v-model="user.mail"
                  label="메일 주소"
                  placeholder="메일 주소를 입력해주세요"
                  hint="메일 주소를 입력해주세요"
                  :rules="[rules.required]"
                />
              </v-list-item>
              <!-- <v-list-item>
                <div>
                  <span class="text-body-2">메일주소 인증여부</span>
                  <br />
                  <v-switch v-model="user.mail_verified" :label="user.mail_verified ? '인증됨' : '인증 안 됨'" />
                </div>
              </v-list-item> -->
              <v-list-item>
                <v-text-field
                  v-model="user.password"
                  :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
                  label="비밀번호"
                  placeholder="비밀번호를 입력해주세요"
                  hint="비밀번호를 입력해주세요"
                  :type="isShowPassword ? 'text' : 'password'"
                  :rules="[rules.required]"
                  @click:append="isShowPassword = !isShowPassword"
                />
              </v-list-item>
              <v-list-item>
                <v-text-field
                  v-model="user.password2"
                  :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
                  label="비밀번호 확인"
                  placeholder="비밀번호를 입력해주세요"
                  hint="비밀번호를 입력해주세요"
                  :type="isShowPassword ? 'text' : 'password'"
                  :rules="[rules.password]"
                  @click:append="isShowPassword = !isShowPassword"
                />
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <!-- <v-col cols="12">
          <v-card class="mx-auto">
            <v-list>
              <v-list-item>
                <v-text-field
                  v-model="user.user_extra.nickname"
                  label="닉네임"
                  placeholder="닉네임을 입력해주세요"
                  hint="닉네임을 입력해주세요"
                  :rules="[rules.required]"
                />
              </v-list-item>

              <v-list-item>
                이미지 입력 필요
                <v-text-field
                  v-model="user.user_extra.real_name"
                  label="실제 이름"
                  placeholder="실제 이름을 입력해주세요"
                  hint="실제 이름을 입력해주세요"
                  :rules="[rules.required]"
                />
              </v-list-item>

              <v-list-item class="mt-4">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="text-subtitle-1">유저 권한 (Only supported json format)</span>
                    <br />
                    <v-checkbox
                      v-model="isAdmin"
                      :label="`관리자 권한: ${isAdmin.toString()}`"
                      on-icon="check_box"
                      off-icon="check_box_outline_blank"
                    />
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <VJsoneditor
                      ref="user_privileges_editor"
                      v-model="user.user_extra.privileges"
                      class="json-editor-wrap"
                      :options="{ mode: 'code' }"
                      :height="'400px'"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-4">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="text-subtitle-1">유저 추가 정보 (Only supported json format)</span>
                    <br />
                    <v-checkbox
                      v-model="isKaKaoAlarmOn"
                      :label="`카카오톡 알림 권한(핸드폰번호를 수정해주세요): ${isKaKaoAlarmOn.toString()}`"
                      on-icon="check_box"
                      off-icon="check_box_outline_blank"
                    />
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <VJsoneditor
                      ref="user_privileges_editor"
                      v-model="user.user_extra.extra"
                      class="json-editor-wrap"
                      :options="{ mode: 'code' }"
                      :height="'400px'"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col> -->
      </v-row>

      <v-row justify="center" class="my-5">
        <v-btn color="primary" class="mx-4" @click="addItem">
          <v-icon small>
            save
          </v-icon>
          <span class="pl-2">저장하기</span>
        </v-btn>

        <v-btn color="" class="mx-4" @click="toPrevPage">
          <v-icon small>
            grading
          </v-icon>
          <span class="pl-2">목록으로</span>
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
// import _ from 'lodash'
import VJsoneditor from 'v-jsoneditor'

import PageTitleComponent from '../_elements/title/PageTitle'
import Api from './model/userApi'
import axios from 'axios'
import Config from '../../config'

export default {
  components: {
    VJsoneditor,
    PageTitleComponent
  },
  data() {
    return {
      valid: false,
      lazy: false,
      isLoading: false,
      isShowPassword: true,
      isAdmin: false,
      isKaKaoAlarmOn: false,
      user: {
        idx: undefined,
        user_id: '',
        mail: '',
        mail_verified: false,
        password: '',
        password2: '',
        user_extra: {
          photo_url: null,
          nickname: '',
          real_name: '',
          privileges: {},
          extra: {}
        }
      },
      rules: {
        required: value => !!value || '꼭 입력해주세요',
        password: value => this.user.password === this.user.password2 || '비밀번호를 확인해주세요.'
      }
    }
  },
  watch: {
    isAdmin(value) {
      this.user.user_extra.privileges = { is_admin: value }
    },
    isKaKaoAlarmOn(value) {
      if (value === true) {
        this.user.user_extra.extra = {
          cellphone: '010-0000-0000',
          is_kakao_alarm: true
        }
      } else {
        this.user.user_extra.extra = {}
      }
    }
  },
  created() {
    this.setup()
  },
  methods: {
    setup() {},
    async addItem() {
      if (this.$refs.form.validate() === false) {
        return
      }

      const result = await axios.post(`${Config.Api.Service2.url}/admin`, {
        username: this.user.user_extra.real_name,
        password: this.user.password,
        email: this.user.mail,
        role: 'manager'
      })

      if (result.status === 201) {
        this.$bus.$emit('successMessage', '관리자가 생성되었습니다')
        this.toPrevPage()
      } else {
        this.$bus.$emit('errorMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    toPrevPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'AdminUsers.Items') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/admin/users?page=1`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.json-editor-wrap {
  ::v-deep .jsoneditor-outer {
    min-height: 200px;
  }
  ::v-deep div.jsoneditor-tree {
    min-height: 200px-35px;
  }
  ::v-deep .jsoneditor-poweredBy {
    display: none;
  }
}
</style>
